

































import {Component, Prop, Vue} from "vue-property-decorator";
import PortalSelect from "@/components/common/PortalSelect.vue";
import AuthFormPayload from "@/dto/archive/AuthFormPayload";
import SelectOption, {OptionsBuilder} from "@/components/common/SelectOption";
import FileUpload from "@/components/common/FileUpload.vue";
import FileMetaDTO from "@/dto/files/FileMetaDTO";
import Workspaces from "@/state/Workspaces";
import {namespace} from "vuex-class";
import {ifValid} from "@/utils/ComponentUtils";
import IndividualTaxationService from "@/services/request/IndividualTaxationService";
import RouteNames from "@/router/RouteNames";
import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";
import {ITRQDocumentType} from "@/constants/request/ITRQDocumentType";
import SingleFileHolder from "@/components/util/SingleFileHolder.vue";
import {SignableDocumentType, SpouseSigningMode} from "@/constants/SignatureConstants";

const AppModule = namespace("App");

@Component({
	components: {SingleFileHolder, FileUpload, PortalSelect}
})
export default class AuthRequestModal extends Vue {

	private message = "";

	private successful = false;

	@AppModule.Action
	private startLoading!: () => void

	@AppModule.Action
	private stopLoading!: () => void

	@Prop()
	private itrqId!: number

	private payload: AuthFormPayload = new AuthFormPayload();

  getSpouseSigningModes(): Array<SelectOption>{
    return OptionsBuilder
        .of("Spouse signature not required", SpouseSigningMode.IGNORED)
        .of("Request spouse signature if spouse is present", SpouseSigningMode.OPTIONAL)
        .of("Spouse signature is mandatory", SpouseSigningMode.REQUIRED)
        .get()
  }

  getDocumentTypes(): Array<SelectOption> {
    return OptionsBuilder
        //Tax return 2024
        .of("Form TR-2000", SignableDocumentType.FORM_TR_2000_2021)
        .of("Form 8879, tax return 2024 (rev. 2021)", SignableDocumentType.FORM_8879_2024_R2021) //check
        .of("Form 4506-T, tax return 2024 (rev. 2024)", SignableDocumentType.FORM_4506_T_R2024)
        .of("Form TR-579-IT, tax return 2024 (rev. 2024)", SignableDocumentType.FORM_TR_579_IT_2024_R2024) // check
        .of("Form 8453 Georgia, tax return 2024 (rev. 2024)", SignableDocumentType.FORM_8453_GEORGIA_R2024) // check
        .of("Form DR-8454 Colorado, tax return 2024 (rev. 2024)", SignableDocumentType.FORM_DR_8454_COLORADO_2024_R2024) // check
        .of("Form PA-8453, tax return 2024 (rev. 2024)", SignableDocumentType.FORM_PA_8453_2024_R2024) // check
        //Revision 2022
        .of("Form M-8453 rev. 2022", SignableDocumentType.FORM_M_8453_MASSACHUSETTS_R2022) // (old) check
        .of("Form VA-8879, rev. 2022", SignableDocumentType.FORM_VA_8879_VIRGINIA_R2022) // (old) check
        .of("Form EL-101, rev. 2022", SignableDocumentType.FORM_EL101_MARYLAND_R2022) // (old) check
        .of("Form TR-579-IT, rev. 2022", SignableDocumentType.FORM_TR_579_IT_R2022) // (old) check
        .of("Form CA-8453, rev. 2022", SignableDocumentType.FORM_CA_8453_R2022) // (old) check
        .of("Form 8453 Georgia, rev. 2022", SignableDocumentType.FORM_8453_GEORGIA_R2022)
        .of("Form DR-8454 Colorado, rev. 2022", SignableDocumentType.FORM_DR_8454_COLORADO_R2022)
        .of("Form SC-8453, rev. 2022", SignableDocumentType.FORM_SC_8453_R2022) // (old) check
        .of("Form AZ-8879, rev. 2022", SignableDocumentType.FORM_AZ_8879_R2022) // (old) check
        //Tax return 2022
        .of("Form 8879, tax return 2022 (rev. 2021)", SignableDocumentType.FORM_8879_2022_R2021)
        // .of("Form IL-8453", SignableDocumentType.FORM_IL_8453_ILLINOIS_2022)
        //Tax return 2021
        .of("Form 8879, tax return 2021 (rev. 2021)", SignableDocumentType.FORM_8879_2021_R2021)
        .of("Form PA-8453, tax return 2021 (rev. 2021)", SignableDocumentType.FORM_PA_8453_2021_R2021)
        .of("Form 2848, rev. 2021", SignableDocumentType.FORM_2848_R2021)
        .of("Form 8879, tax return 2020 (rev. 2021)", SignableDocumentType.FORM_8879_2020_R2021)
        .of("Form PA-8453, tax return 2020 (rev. 2020)", SignableDocumentType.FORM_PA_8453_2020_R2020)
        .of("Form PA-8453, tax return 2019 (rev. 2019)", SignableDocumentType.FORM_PA_8453_2019_R2019)
        .of("Form 8879, tax return 2019 (rev. 2019)", SignableDocumentType.FORM_8879_2019_R2019)
        .of("Form POA-1, rev. 2017", SignableDocumentType.FORM_POA_1_R2017)
        // .of("Form 2553", SignableDocumentType.FORM_2553)
        .get()
  }

	private send(): void {
		ifValid(this, () => {
			this.startLoading();
			IndividualTaxationService.sendAuthForm(this.itrqId ,this.payload).then(
				() => {
					this.stopLoading();
					this.successful = true
					this.$modal.hideAll()
					this.$router.push({name: RouteNames.ARCHIVE_SIGNABLE_LIST_NEW, params: this.$route.params})
				},
				err => this.processError(err)
			)
		})
	}

	private processError(err: any) {
		this.successful = false;
		this.message = this.$t(ErrorLocalePathUtils.getPathForError(err)) as string;
		this.stopLoading();
	}
}
